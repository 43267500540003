
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Hidden, Button, TextField, Checkbox, Icon } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import PortableText from "../portableText";
import GatedContentEmailSignupWidget from "../widgets/gatedContentEmailSignupWidget/gatedContentEmailSignupWidget";
import Tint from "./img/tint.svg";
import * as styles from "./originalFaded.module.css";
import GatedContentOriginalNoFade from "./originalNoFade";
import GatedContentOriginalFaded from "./originalFaded";
import GatedContentBlueHeadingWithKey from "./blueHeadingWithKey";

import * as uuid from 'uuid'

const useStyles = makeStyles((theme) => ({
  fadedContainer: {
    backgroundImage: 'url(./img/tint.svg)'
  }
}));

const ArticleGatedContentDecider = ({
  enableGatedContent,
  gateStyle,
  emailSignupWidgetTitle,
  emailSignupWidgetImage,
  emailSignupWidgetParagraphText,
  emailSignupWidgetButtonText,
  textNextToKey,
  emailSignupWidgetTextboxPlaceholder,
  _rawGatedContentBody,
  _rawGatedContentReadMoreText,
  brazeCustomAttributes,
  _rawBody,
  showGate,
}) => {
  const classes = useStyles();

  const [emailCaptured, setEmailCaptured] = useState(false);

  //console.log("res-blog-post", brazeSubscriptionRefGate);

  useEffect(() => {


   (async () => {
     var res = showGate;

     if (res) {
       setEmailCaptured(showGate);
     }
      })();
    }, []);

  const subscribedCallback = () => {
    window.scrollTo(0, 0);

    setEmailCaptured(true);
  }

  return (
    <>
      {(!enableGatedContent || (enableGatedContent && emailCaptured)) && _rawBody && <PortableText blocks={_rawBody} />}

      {enableGatedContent && !emailCaptured && gateStyle === 'Original - No fade' && <GatedContentOriginalNoFade
        emailSignupWidgetTitle={emailSignupWidgetTitle}
        emailSignupWidgetImage={emailSignupWidgetImage}
        emailSignupWidgetParagraphText={emailSignupWidgetParagraphText}
        emailSignupWidgetButtonText={emailSignupWidgetButtonText}
        emailSignupWidgetTextboxPlaceholder={emailSignupWidgetTextboxPlaceholder}
        _rawGatedContentBody={_rawGatedContentBody}
        _rawGatedContentReadMoreText={_rawGatedContentReadMoreText}
        subscribedCallback={subscribedCallback}
        brazeCustomAttributes={brazeCustomAttributes}
      />}

      {enableGatedContent && !emailCaptured && gateStyle === 'Original - Faded' && <GatedContentOriginalFaded
        emailSignupWidgetTitle={emailSignupWidgetTitle}
        emailSignupWidgetImage={emailSignupWidgetImage}
        emailSignupWidgetParagraphText={emailSignupWidgetParagraphText}
        emailSignupWidgetButtonText={emailSignupWidgetButtonText}
        emailSignupWidgetTextboxPlaceholder={emailSignupWidgetTextboxPlaceholder}
        _rawGatedContentBody={_rawGatedContentBody}
        _rawGatedContentReadMoreText={_rawGatedContentReadMoreText}
        subscribedCallback={subscribedCallback}
        brazeCustomAttributes={brazeCustomAttributes}
      />}

      {enableGatedContent && !emailCaptured && gateStyle === 'Blue heading with key and text central - faded' && <GatedContentBlueHeadingWithKey
        emailSignupWidgetTitle={emailSignupWidgetTitle}
        emailSignupWidgetImage={emailSignupWidgetImage}
        emailSignupWidgetParagraphText={emailSignupWidgetParagraphText}
        emailSignupWidgetButtonText={emailSignupWidgetButtonText}
        emailSignupWidgetTextboxPlaceholder={emailSignupWidgetTextboxPlaceholder}
        _rawGatedContentBody={_rawGatedContentBody}
        _rawGatedContentReadMoreText={_rawGatedContentReadMoreText}
        subscribedCallback={subscribedCallback}
        brazeCustomAttributes={brazeCustomAttributes}
        textNextToKey={textNextToKey}
      />}


    </>
  );
};


export default ArticleGatedContentDecider;
