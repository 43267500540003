import { graphql } from "gatsby";
import BlogPost from "../components/blog-post";
import React from "react";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import { toPlainText, getBlogUrl, getPageUrlOutOfSanityReference } from "../lib/helpers";

export const query = graphql`
query BlogPostTemplateQuery($id: String!) {
  post: sanityPost(id: {eq: $id}) {
    enableComments
    enableGatedContent
    gateStyle
    hideRelatedContent
    emailSignupWidgetTitle
    emailSignupWidgetImage {
      ...SanityImage
      alt
    }
    emailSignupWidgetParagraphText
    textNextToKey
    emailSignupWidgetButtonText
    brazeCustomAttributes {
        ... fragSanityBrazeCustomAttribute
    }
    emailSignupWidgetTextboxPlaceholder
    _rawGatedContentBody(resolveReferences: {maxDepth: 5})
    _rawGatedContentReadMoreText(resolveReferences: {maxDepth: 5})
    id
    _id
    publishedAt
    _type
    categories {
      _id
      title
    }
    mainImage {
      ...SanityImage
      alt
    }
    title
    subtitle
    template
    hideSocialIconsTop
    slug {
      current
    }
    _rawExcerpt(resolveReferences: {maxDepth: 5})
    _rawAuthorsText(resolveReferences: {maxDepth: 5})
    _rawBody(resolveReferences: {maxDepth: 10})
    authors {
      _key
      people {
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        name
      }
    }
    widgets {
      widgetType {
        title
      }
      title
      adZone
      mobileAdZone
      _rawBody
      _rawMainImage
      id
      mainImage {
        ...SanityImage
        alt
      }
      codeSnippet {
        children {
          text
        }
      }
      linkReference {
        ... fragLinkReference
      }

    }
    linkMenu {
      menuLinksTop {
        text
        reference {
          AnchorName
        }
        menuChildLinks {
          text
          reference {
            AnchorName
          }
        }
      }
      menuTitle

    }
    canonicalTagOverride
    brazeCustomAttributesSB {
       ... fragSanityBrazeCustomAttribute
    }
  }
  allArticles: allSanityPost (sort: {order: DESC, fields: [publishedAt]},  limit: 200
     filter: {publishedAt: {ne: null, lt: "2030-01-01"}}){
     nodes {
        ... fragAllArticles
     }
  }
}
`;


const BlogPostTemplate = (props) => {
  const { data, errors } = props;

  const post = data && data.post;
  const getPageUrl = getPageUrlOutOfSanityReference(post)

  post.allArticles = data && data.allArticles

  try {
    return (
      <Layout>
        {errors && <SEO title="GraphQL Error" />}
        {post && (
          <SEO
            title={post.title || "Untitled"}
            description={toPlainText(post._rawExcerpt)}
            image={post.mainImage}
            canonical={!post.canonicalTagOverride && getPageUrl}
            canonicalTagOverride={post.canonicalTagOverride}
          />
        )}

        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )}

        {post && <BlogPost {...post} />}
      </Layout>
    );
  } catch (e) {
    console.log("blog-post", e);
    return (null);
  }

};

export default BlogPostTemplate;
